@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Open Sans", sans-serif;
}
h1 {
  font-weight: 700;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
p {
  font-weight: 400;
  color: #8c8c8c;
}
ul,
ol {
  margin-bottom: 0.67em;
}
li {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin-bottom: 0.67em;
  color: #8c8c8c;
}
.container {
  position: relative;
  max-width: 1280px;
  margin: 40px auto 40px auto;
  overflow-y: auto;
}
.container strong {
  font-size: 20px;
  line-height: 26px;
}
.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
}
.container a {
  text-decoration: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.white-bg {
  background: #fff;
}
.lt-gr-bg {
  background: #f6f6f6;
}
.primary-bg {
  background: var(--ion-color-primary);
}
.secondary-bg {
  background: var(--ion-color-secondary);
}
.white-color {
  color: #fff !important;
}
.black-color {
  color: #000 !important;
}
.gr-border {
  border: solid #e4e4e4 2px !important;
}
.light-gr-bg {
  background: #eee;
}
.no-border {
  border: none !important;
}
.w-100 {
  width: 100% !important;
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.page-title {
  font-size: 28px;
  padding: 0 20px;
  color: #002f5f;
  font-weight: 700;
}
.modal-wrapper.sc-ion-modal-md {
  /*height: 500px !important;*/
  top: 30% !important;
}
.noshadow {
  --border-width: 0 !important;
}
.nozindex {
  z-index: 0 !important;
}
.hamburger {
  font-size: 40px;
  color: #002f5f;
}
.error {
  background: #eb445a;
  color: #fff;
  padding: 10px 0;
}
a:link,
a:visited,
a:focus {
  color: #000;
  text-decoration: underline;
}
.status-border {
  border-right: 1px solid;
}

.table-border {
  border: solid 1px #eee;
  padding: 0 10px;
}

.notes-min-width {
  min-width: 66px;
}
.table-header {
  border: solid 1px #eee;
  padding: 0 10px;
}
.mobile-table-header {
  display: none;
}
.table-header p {
  margin: 10px 0;
}
.m-0 {
  margin: 0;
}
.m-1 {
  margin: 10px;
}
.m-2 {
  margin: 20px;
}
.m-3 {
  margin: 30px;
}
.m-4 {
  margin: 40px;
}
.m-5 {
  margin: 50px;
}
.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 20px;
}
.ml-3 {
  margin-left: 30px;
}
.ml-4 {
  margin-left: 40px;
}
.ml-5 {
  margin-left: 50px;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}
.mt-5 {
  margin-top: 50px;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 20px;
}
.mr-3 {
  margin-right: 30px;
}
.mr-4 {
  margin-right: 40px;
}
.mr-5 {
  margin-right: 50px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mb-4 {
  margin-bottom: 40px;
}
.mb-5 {
  margin-bottom: 50px;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 10px;
}
.p-2 {
  padding: 20px;
}
.p-3 {
  padding: 30px;
}
.p-4 {
  padding: 40px;
}
.p-5 {
  padding: 50px;
}
.pl-0 {
  padding: 0;
}
.pl-1 {
  padding-left: 10px !important;
}
.pl-2 {
  padding-left: 20px;
}
.pl-3 {
  padding-left: 30px;
}
.pl-4 {
  padding-left: 40px;
}
.pl-5 {
  padding-left: 50px;
}
.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 10px;
}
.pt-2 {
  padding-top: 20px;
}
.pt-3 {
  padding-top: 30px;
}
.pt-4 {
  padding-top: 40px;
}
.pt-5 {
  padding-top: 50px;
}
.pr-0 {
  padding-right: 0;
}
.pr-1 {
  padding-right: 10px !important;
}
.pr-2 {
  padding-right: 20px;
}
.pr-3 {
  padding-right: 30px;
}
.pr-4 {
  padding-right: 40px;
}
.pr-5 {
  padding-right: 50px;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 10px;
}
.pb-2 {
  padding-bottom: 20px;
}
.pb-3 {
  padding-bottom: 30px;
}
.pb-4 {
  padding-bottom: 40px;
}
.pb-5 {
  padding-bottom: 50px;
}
.last-four {
  font-size: 25px;
}
.m-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.d-block {
  display: block !important;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.h-100 {
  height: 100% !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
ion-header {
  border-bottom: #ccc solid 3px;
}
.bar-header {
  border: 0px !important;
  border-bottom-color: transparent !important;
  background-image: none !important;
  border-bottom: none !important;
}
.header-shadow {
  box-shadow: 0px 10px 10px #ccc;
}
bottom-border {
  border-bottom: solid 2px #ccc;
}
.header-row {
  max-width: 1280px;
  margin: auto auto;
  padding-left: 10px;
  padding-right: 10px;
}
.header-text-center {
  margin: 25px 0 25px 0;
}
.header-title-div {
  display: inline-block;
  width: 80%;
  position: relative;
  bottom: 15%;
  left: 2%;
}
.toggle-icon {
  position: absolute;
  top: calc(50% - 10px);
  left: 60%;
}
.hover-cursor {
  cursor: pointer;
}
.active {
  display: block;
}
.inactive {
  display: none;
}
/* Footer Styling */
.footer {
  background-image: url("../images/ft-bg.jpg");
  background-size: cover;
  background-position: center bottom;
  border-top: solid #002f5f 15px;
  position: relative;
  margin: 0;
  bottom: 0;
}
.footer-border-top {
  border-top: 8px #fff solid;
}
.disclaimer {
  font-size: 14px !important;
  line-height: 16px !important;
}
.footer-contact-info {
  font-size: 1.2em !important;
  line-height: 26px !important;
}
.soc-container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
.soc-lnk {
  margin: 0 auto;
  display: block;
}
.copyright {
  background: #002f5f;
  color: #fff;
  margin: 0;
  text-align: center;
  width: 100%;
}
.input-mask {
  height: 50px;
  padding-left: 10px;
}
.main-page {
  min-height: calc(100% - 500px);
}

.global-actions .action-sheet-container{
  position: relative;
  justify-content: center;
  
}

.global-actions .action-sheet-group{
  bottom: 0;
  position: relative;
  border-radius: 5px;
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .toggle-icon {
    position: absolute;
    top: calc(50% - 10px);
    left: 30%;
  }

  .status-border {
    border-bottom: 1px solid;
  }
}

@media only screen and (max-width: 990px) {
  .headers {
    display: none;
  }
  .table-section {
    margin-bottom: 40px;
    border: solid 4px #eee;
  }
  .mobile-table-header {
    display: block;
    padding: 0 10px;
    background: #eee;
  }
  .mobile-table-header p {
    margin: 10px 0;
  }
  .container {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 400px) {
  .toggle-icon {
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
  }
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

ion-input.FileUpload {
  border: 2px solid lightgrey;
  border-radius: unset;
  margin-top: 2px;
  padding-left: 10px !important;
  height: 30px;
  font-size: large;
}

ion-label.FileUpload {
  padding-top: 5px;
}

ion-item.FieldError {
  margin-top: 1px;
  --min-height: 30px;
  --padding-start: 0px;
  --border-width: unset;
  color: red;
}

div.ModalHeader {
  margin-left: auto;
  padding-top: 10px;
  font-size: 20px;
  font-weight: bold;
}

ion-icon.ModalHeader {
  margin-left: auto;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}

ion-toast.AssetGroupToast {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  --max-width: 500px;
  font-size: large;
  font-weight: bold;
}

ion-content.iframe-wrapper {
  height: 400px;
}

.ApplicationActions>ion-button{
  text-wrap: balance;
  height: '30px';
  margin-top: '20px';
  /* margin-left: '20px'; */
  width: '320px';
}

.ApplicationActions{
  flex-direction: column;
  max-width: 404px;
  padding-top: 15px
}

ion-content.lt-gray-bg{
  --background: var(--lt-gr-bg);
}

ion-col.modal-wrapper:has(ion-row#main-fields){
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 65%;
}

ion-col.modal-wrapper{
  width: 80%; 
}

ion-col.modal-wrapper:has(ion-row.ApplicationActions){
  height: 95% !important
}