:root {
  --lt-gr-bg: #eee
}

.transaction-info-modal .close-button{
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
}

.transaction-info-modal .modal-wrapper.ion-overlay-wrapper.sc-ion-modal-md{
  position: fixed;
}

.transaction-info-modal .modal-wrapper.ion-overlay-wrapper.sc-ion-modal-ios{
  position: fixed;
  background: var(--lt-gr-bg);
}

/* @media only screen and (max-height: 630px){
  ion-modal.transaction-info-modal{
    --height: 80%;
  } 
} */

@media only screen and (min-height: 700px){
  ion-modal.transaction-info-modal{
  --height: 668px;
  } 
}

@media only screen and (min-height: 600px){
  ion-modal.transaction-info-modal:has(ion-row#main-fields){
    --height: 580px;
  }
}

.transaction-info-modal .modal-wrapper{
  margin: auto;
  max-width: auto;
}

.transaction-info-modal .fill-row{
  display: grid;
  height: 100%;
  width: 100%;
}

.loading-modal .modal-wrapper.ion-overlay-wrapper.sc-ion-modal-md{
    position: fixed;
}

.loading-modal .modal-wrapper{
    margin: auto;
    max-height: 100px;
    max-width: 375px;
  }
  
@media(prefers-color-scheme: dark){
  :root{
    --lt-gr-bg: #615E70
  }
    .loading-modal{
      --border-radius: 10px;
      --backdrop-opacit:90%;
      --background: rgba(0, 0, 0, 0.8);
      --height: 10%
  }
}

@media(prefers-color-scheme: light){
    .loading-modal{
      --border-radius: 10px;
      --backdrop-opacit:90%;
      --background: rgba(255, 255, 255, 0.8);
      --height: 10%
  }
}

.loading {
  font-size: 20px;
  font-weight: bold;
  margin: auto;
  width: 55%;
  padding: 20px;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 2.5s infinite;
  animation: ellipsis steps(4,end) 2.5s infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}